// react
import { useState, memo } from 'react';
// @mui
import Box from '@mui/material/Box';
// components
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/Iconify';

function FullScreenButton() {
  const [fullscreen, setFullscreen] = useState(document.fullscreenElement ? true : false);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setFullscreen(false);
    }
  };

  return (
    <IconButtonAnimate onClick={toggleFullScreen}>
      <Box
        sx={{
          lineHeight: 0,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.standard,
            }),
        }}
      >
        <Iconify icon={fullscreen ? 'ic:round-fullscreen-exit' : 'ic:round-fullscreen'} />
      </Box>
    </IconButtonAnimate>
  );
}

export default memo(FullScreenButton);
