// react
import { ReactNode, createContext, useState } from 'react';

// ----------------------------------------------------------------------

export type CookiesPreferencesContextProps = {
  analytics: boolean;
  onSetAnalytics: (analytics: boolean) => void;
};

const initialState: CookiesPreferencesContextProps = {
  analytics: false,
  onSetAnalytics: () => {},
};

const CookiesPreferencesContext = createContext(initialState);

type CookiesPreferencesProviderProps = {
  children: ReactNode;
};

function CookiesPreferencesProvider({ children }: CookiesPreferencesProviderProps) {
  const [analytics, setAnalytics] = useState<boolean>(false);

  const handleSetAnalytics = (newAnalytics: boolean) => {
    setAnalytics(newAnalytics);
  };

  return (
    <CookiesPreferencesContext.Provider
      value={{
        analytics,
        onSetAnalytics: handleSetAnalytics,
      }}
    >
      {children}
    </CookiesPreferencesContext.Provider>
  );
}

export { CookiesPreferencesProvider, CookiesPreferencesContext };
