// react
import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box sx={{ width: 30, height: 30, mb: 2, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="210.7 215.3 258.6 371.2">
        <title>dratio.io</title>

        <g id="Layer 1">
          <g id="dratio_logo">
            <path
              id="&lt;Path&gt;"
              fill="#007b55"
              d="m400.9 525.6l-60.9 60.9-91.4-91.4c-25.3-25.2-37.9-58.3-37.9-91.4v-112.2h86.2v21.4 90.8q0 2.1 0.2 4.2c0.1 0.7 0.2 1.3 0.3 1.9 0.1 0.8 0.2 1.5 0.3 2.3 0.2 0.7 0.4 1.4 0.6 2.1 0.1 0.7 0.3 1.3 0.5 2 0.2 0.7 0.4 1.4 0.7 2 0.2 0.7 0.4 1.3 0.7 2 0.3 0.6 0.6 1.2 0.9 1.8 0.3 0.7 0.6 1.4 0.9 2 0.3 0.6 0.7 1.2 1 1.7 0.4 0.7 0.8 1.3 1.2 1.9 0.4 0.6 0.9 1.3 1.3 1.9 0.4 0.5 0.8 1 1.2 1.5q1.3 1.6 2.8 3.1 0 0 0 0.1l86.1 86.1z"
            />
            <path
              id="&lt;Path&gt;"
              fill="#00ab55"
              d="m383.1 253.4v59.5 90.8q0-2.2-0.2-4.3c-0.1-0.6-0.2-1.2-0.3-1.9-0.1-0.7-0.2-1.5-0.3-2.2-0.2-0.8-0.4-1.5-0.6-2.2-0.1-0.6-0.3-1.3-0.5-1.9-0.2-0.7-0.4-1.4-0.7-2.1-0.2-0.6-0.4-1.3-0.7-1.9-0.3-0.6-0.6-1.3-0.9-1.9-0.3-0.6-0.6-1.3-0.9-1.9-0.3-0.6-0.7-1.2-1-1.8-0.4-0.6-0.8-1.2-1.2-1.8-0.4-0.7-0.9-1.3-1.3-1.9-0.4-0.5-0.8-1-1.2-1.5q-2.7-3.3-6-6c-0.5-0.4-1-0.8-1.5-1.2-0.6-0.4-1.2-0.9-1.9-1.4-0.6-0.4-1.2-0.7-1.8-1.1-0.6-0.3-1.2-0.7-1.8-1-0.6-0.4-1.3-0.6-1.9-1-0.7-0.3-1.3-0.6-1.9-0.8-0.6-0.3-1.3-0.5-1.9-0.7-0.7-0.3-1.4-0.5-2.1-0.8-0.6-0.1-1.3-0.3-1.9-0.4-0.7-0.2-1.4-0.4-2.2-0.6-0.7-0.1-1.5-0.2-2.2-0.3-0.7-0.1-1.3-0.2-1.9-0.3q-2.2-0.2-4.3-0.2 0 0 0 0-2.1 0-4.3 0.2c-0.6 0.1-1.2 0.2-1.9 0.3-0.7 0.1-1.5 0.2-2.2 0.3-0.8 0.2-1.5 0.4-2.2 0.6-0.6 0.1-1.3 0.3-1.9 0.4-0.7 0.3-1.4 0.5-2.1 0.8-0.6 0.2-1.3 0.4-1.9 0.7-0.6 0.2-1.2 0.5-1.9 0.8-0.6 0.3-1.3 0.6-1.9 1-0.6 0.3-1.2 0.7-1.8 1-0.6 0.4-1.2 0.7-1.8 1.1-0.7 0.5-1.3 0.9-1.9 1.4-0.5 0.4-1 0.7-1.5 1.2q-1.7 1.3-3.2 2.8 0 0 0 0-1.5 1.5-2.8 3.2c-0.4 0.5-0.8 1-1.2 1.5-0.4 0.6-0.9 1.2-1.3 1.9-0.4 0.6-0.8 1.2-1.2 1.8-0.3 0.6-0.7 1.2-1 1.8-0.3 0.6-0.6 1.3-1 1.9-0.2 0.7-0.5 1.3-0.8 1.9-0.3 0.6-0.5 1.3-0.7 1.9-0.3 0.7-0.5 1.4-0.7 2.1-0.2 0.6-0.4 1.3-0.5 2-0.2 0.7-0.4 1.3-0.6 2.1-0.1 0.7-0.2 1.5-0.3 2.3-0.1 0.6-0.2 1.2-0.3 1.8q-0.2 2.1-0.2 4.3v-90.8-59.5z"
            />
            <path
              id="&lt;Path&gt;"
              fill="#5be584"
              d="m469.3 215.3v188.4c0 33.1-12.6 66.2-37.9 91.4l-30.5 30.5-5.3-5.3-55.6-55.7 30.5-30.4q0 0 0 0 1.5-1.6 2.8-3.2c0.4-0.5 0.8-1 1.2-1.5 0.4-0.6 0.9-1.3 1.3-1.9 0.4-0.6 0.8-1.2 1.2-1.9 0.3-0.5 0.7-1.1 1-1.7 0.3-0.6 0.6-1.3 0.9-2 0.3-0.6 0.6-1.2 0.9-1.8 0.3-0.7 0.5-1.3 0.7-1.9 0.3-0.7 0.5-1.4 0.7-2.1 0.2-0.6 0.4-1.3 0.5-1.9 0.2-0.8 0.4-1.5 0.6-2.2 0.1-0.8 0.2-1.5 0.3-2.3 0.1-0.6 0.2-1.2 0.3-1.9q0.2-2.1 0.2-4.2v-90.8-97.6z"
            />
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}

export default memo(Logo);
