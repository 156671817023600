// react
import { useContext } from 'react';
// context
import { CollapseDrawerContext } from '../contexts/CollapseDrawerContext';

// ----------------------------------------------------------------------

const useCollapseDrawer = () => useContext(CollapseDrawerContext);

export default useCollapseDrawer;
