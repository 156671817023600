// react
import { useState, ReactNode, lazy } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// components
import LoadingScreen from '../components/LoadingScreen';
import { Loadable } from '../components/Loadable';
// routes
import { PATH } from '../routes/paths';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, user } = useAuth();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  // auth steps guard
  if (user === null) {
    return <LoadingScreen />;
  }

  if (!user.isVerified) {
    return <Navigate to={PATH.verify} />;
  }

  if (!user.isWelcome || !user.isApproved) {
    return <Navigate to={PATH.welcome} />;
  }

  return <>{children}</>;
}

const Login = Loadable(lazy(() => import('../pages/auth/Login')));
