// react
import { useLocation, Outlet } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
// components
import Logo from '../../components/LogoFull';
//
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />

      <Outlet />

      <Box sx={{ flexGrow: 1 }} />

      {!isHome ? (
        <MainFooter />
      ) : (
        <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default',
          }}
        >
          <Container>
            <Logo sx={{ mb: 4, mx: 'auto' }} />

            <Typography variant="caption" component="p">
              © All rights reserved. &nbsp;
              <Link href="https://menhir.ai">Menhir Financial</Link>.
              <br /> Engineered in Madrid.
              {/* <Link href="https://dratio.io/">dratio.io</Link> */}
            </Typography>
          </Container>
        </Box>
      )}
    </Stack>
  );
}
