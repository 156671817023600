// react
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
// routes
import { PATH, SOCIALS } from '../../routes/paths';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
// import Logo from '../../components/Logo';
import Image from '../../components/Image';
import SocialsButton from '../../components/SocialsButton';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Company',
    children: [
      { name: 'FAQs', href: PATH.faqs },
      { name: 'About', href: 'https://www.menhir.ai/company', external: true },
      { name: 'Blog', href: 'https://www.menhir.ai/case-studies', external: true },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Conditions', href: PATH.legal.terms },
      { name: 'Privacy Policy', href: PATH.legal.privacy },
      { name: 'Cookies Policy', href: PATH.legal.cookies },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'Contact us', href: PATH.contact },
      { name: 'info@menhir.ai', href: 'mailto:info@menhir.ai', external: true },
      { name: '© 2024. Menhir Financial.', href: 'https://menhir.ai', external: true },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const idMd = useResponsive('up', 'lg');

  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Stack direction={idMd ? 'row' : 'column'} alignItems="center">
              {/* <Logo sx={{  mx: { xs: 'auto', md: 'inherit' }}} /> */}
              <Image
                alt={'Menhir'}
                src={isLight ? '/menhir/menhir_light.svg' : '/menhir/menhir_dark.svg'}
                sx={{ width: 160, height: 60, mx: { xs: 'auto', md: 'inherit' } }}
              />
            </Stack>
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              Join the data-centric revolution with&nbsp;
              <Link component={RouterLink} to={PATH.home} color="inherit">
                Dratio
              </Link>
              . A{' '}
              <Link href="https://menhir.ai" color="inherit">
                Menhir Financial
              </Link>{' '}
              product for analyzing and improving your decisions through data.
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton links={SOCIALS} sx={{ mx: 0.5, mb: 5 }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              sx={{ mb: 5 }}
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link) =>
                    link.href ? (
                      link?.external ? (
                        <Link
                          href={link.href}
                          key={link.name}
                          color="inherit"
                          variant="body2"
                          sx={{ display: 'block' }}
                        >
                          {link.name}
                        </Link>
                      ) : (
                        <Link
                          to={link.href}
                          key={link.name}
                          color="inherit"
                          variant="body2"
                          component={RouterLink}
                          sx={{ display: 'block' }}
                        >
                          {link.name}
                        </Link>
                      )
                    ) : (
                      <Typography
                        key={link.name}
                        component="p"
                        variant="body2"
                        sx={{
                          pb: 12,
                          textAlign: { xs: 'center', md: 'left' },
                        }}
                      >
                        {link.name}
                      </Typography>
                    )
                  )}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
