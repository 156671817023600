// react
import { Suspense, ElementType } from 'react';
import { useLocation } from 'react-router-dom';
// components
import LoadingScreen from './LoadingScreen';
// hooks
import useAuth from '../hooks/useAuth';
// path
import { PATH } from '../routes/paths';

export const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes(PATH.app.root) && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};
