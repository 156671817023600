// react
import { memo } from 'react';
// @mui
import Box from '@mui/material/Box';
// components
import { IconButtonAnimate } from './animate';
import Iconify from './Iconify';
import useSettings from '../hooks/useSettings';

function ThemeIconButton() {
  const { onToggleMode, themeMode } = useSettings();

  return (
    <IconButtonAnimate onClick={onToggleMode}>
      <Box
        sx={{
          lineHeight: 0,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.standard,
            }),
        }}
      >
        <Iconify icon={themeMode === 'light' ? 'ph:sun-duotone' : 'ph:moon-duotone'} />
      </Box>
    </IconButtonAnimate>
  );
}

export default memo(ThemeIconButton);
