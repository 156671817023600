// react
import { createContext, ReactNode, useEffect, useReducer } from 'react';
// recaptcha
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import {
  ActionMap,
  AuthState,
  AuthUser,
  JWTContextType,
  LoginResponseType,
  User,
  BackendUser,
} from '../@types/auth';
// routes
import { PATH_API } from '../routes/paths';
// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: undefined;
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

function transformUser(user: BackendUser): User {
  const frontUser: User = {
    id: user.email,
    displayName: `${user.first_name} ${user.last_name}`,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    // additional data
    phone: user.phone,
    country: user.country,
    address: user.address,
    city: user.city,
    zipCode: user.zip_code,
    sector: user.sector,
    company: user.company,
    // settings
    theme: user.theme,
    language: user.language,
    // plan
    role: user.role,
    plan: user.plan,
    // state in application
    isVerified: user.is_verified,
    isWelcome: user.is_welcome,
    isApproved: user.is_approved,
    // notifications
    activitySubscriptions: user.notifications_subscriptions,
    activityNewDatasets: user.notifications_data,
    activitySecurity: user.notifications_security,
    applicationNews: user.notifications_news,
    applicationPromotions: user.notifications_promotions,
    applicationBlog: user.notifications_blog,
  };

  return frontUser;
}

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken, refreshToken, executeRecaptcha);

          const { data }: { data: BackendUser } = await axios.get(PATH_API.auth.account);
          const user = transformUser(data);

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, [executeRecaptcha]);

  const login = async (email: string, password: string, recaptcha: string) => {
    const response: { data: LoginResponseType } = await axios.post(PATH_API.auth.login, {
      username: email,
      password,
      recaptcha,
    });

    const { access: accessToken, refresh: refreshToken } = response.data;

    setSession(accessToken, refreshToken, executeRecaptcha);

    const { data }: { data: BackendUser } = await axios.get(PATH_API.auth.account);
    const user = transformUser(data);

    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const loginWithToken = async (token: string, recaptcha: string) => {
    const response: { data: LoginResponseType } = await axios.post(PATH_API.auth.social, {
      token,
      recaptcha,
    });
    const { access: accessToken, refresh: refreshToken } = response.data;

    setSession(accessToken, refreshToken, executeRecaptcha);

    const { data }: { data: BackendUser } = await axios.get(PATH_API.auth.account);
    const user = transformUser(data);

    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const verify = async (accessToken: string | null, refreshToken: string | null) => {
    setSession(accessToken, refreshToken, executeRecaptcha);

    const { data }: { data: BackendUser } = await axios.get(PATH_API.auth.account);
    const user = transformUser(data);

    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    recaptcha: string
  ) => {
    await axios.post(PATH_API.auth.account, {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      recaptcha,
    });
    setSession(null, null, null);
    dispatch({ type: Types.Register });
  };

  const logout = async () => {
    setSession(null, null, null);
    dispatch({ type: Types.Logout });
  };

  const refresh = async () => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken, refreshToken, executeRecaptcha);

      const { data }: { data: BackendUser } = await axios.get(PATH_API.auth.account);
      const user = transformUser(data);

      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        refresh,
        verify,
        loginWithToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
