// react
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

type VerificationProps = {
  children: ReactNode;
};

export default function VerificationGuard({ children }: VerificationProps) {
  const { isAuthenticated, user, isInitialized } = useAuth();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (isAuthenticated && user?.isVerified) {
    return <Navigate to={PATH_AFTER_LOGIN} />;
  }

  return <>{children}</>;
}
