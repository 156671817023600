// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

// ----------------------------------------------------------------------

export const BASE = 'https://dratio.io';

// ----------------------------------------------------------------------

const ROOT = '/';
const ROOTS_MARKETPLACE = '/marketplace/';
const ROOTS_LEGAL = '/legal/';
const ROOTS_CAREERS = '/about/';
const ROOTS_APP = '/app/';
const ROOTS_BLOG = '/blog/';
const ROOTS_ACCOUNT = path(ROOTS_APP, 'account/');
const ROOTS_APP_MARKETPLACE = '/app/marketplace/';

// ----------------------------------------------------------------------

const PUBLIC_URL = `${process.env.PUBLIC_URL}/`;

// ----------------------------------------------------------------------

const marketplacePath = (root: string) => ({
  root: root,
  dataset: (name: string) => path(root, `dataset/${name}/`),
  feature: (name: string) => path(root, `feature/${name}/`),
  variable: (name: string) => path(root, `variable/${name}/`),
  source: (name: string) => path(root, `source/${name}/`),
  category: (name: string) => path(root, `category/${name}/`),
});

// ----------------------------------------------------------------------

export const PATH = {
  root: ROOT,
  home: ROOT,
  solutions: '/solutions/',
  contact: '/contact/',
  login: '/login/',
  getStarted: '/getstarted/',
  verify: '/verification/:token/',
  requestVerify: '/verification/',
  forgot: '/forgot/',

  newPassword: '/forgot/:token/',
  welcome: '/welcome/',
  license: (name: string) => `/license/${name}`,
  unsubscribe: (token: string) => `/unsubscribe/${token}/`,
  faqs: '/faqs/',
  public: (file: string) => path(PUBLIC_URL, file),
  blog: {
    root: ROOTS_BLOG,
    post: (name: string) => path(ROOTS_BLOG, `${name}/`),
  },
  careers: {
    root: ROOTS_CAREERS,
    career: (name: string) => path(ROOTS_CAREERS, `${name}/`),
    avatar: (name: string) => path(PUBLIC_URL, `assets/icons/career/${name}.png`),
  },
  legal: {
    root: ROOTS_LEGAL,
    privacy: path(ROOTS_LEGAL, 'privacy/'),
    terms: path(ROOTS_LEGAL, 'terms/'),
    cookies: path(ROOTS_LEGAL, 'cookies/'),
  },
  marketplace: marketplacePath(ROOTS_MARKETPLACE),
  app: {
    root: ROOTS_APP,
    account: {
      root: ROOTS_ACCOUNT,
      invoice: path(ROOTS_ACCOUNT, 'invoices/'),
    },
    explore: path(ROOTS_APP, 'explore/'),
    laboratory: path(ROOTS_APP, 'laboratory/'),
    notifications: path(ROOTS_APP, 'notifications/'),
    marketplace: marketplacePath(ROOTS_APP_MARKETPLACE),
    api: path(ROOTS_APP, 'api/'),
    urban: path(ROOTS_APP, 'urban/'),
    logout: path(ROOTS_APP, 'logout/'),
  },
};

// ----------------------------------------------------------------------

export const PATH_API = {
  auth: {
    password: '/account/password/',
    login: '/account/login/',
    verify: '/account/verification/',
    resend: '/account/resend/',
    social: '/account/social/',
    forgot: '/account/forgot/',
    reset: '/account/reset/',
    account: '/account/',
    refresh: '/account/refresh/',
    unsubscribe: '/account/unsubscribe/',
    token: '/account/token/',
    tokenDeletion: '/account/token/delete/',
    usage: '/account/usage/',
  },
  marketplace: {
    datasets: '/marketplace/dataset/',
    dataset: (id: string) => `/marketplace/dataset/${id}`,
    datasetFiles: (id: string) => `/marketplace/dataset/${id}/file/`,
    fileDownload: (id: string) => `/marketplace/file/${id}/download/`,
    sources: '/marketplace/publisher/',
    source: (id: string) => `/marketplace/publisher/${id}`,
    features: '/marketplace/feature/',
    feature: (id: string) => `/marketplace/feature/${id}`,
    categories: '/marketplace/category/',
    scopes: '/marketplace/scope/',
    levels: '/marketplace/level/',
    documentation: (id: string) => `/marketplace/documentation/${id}/`,
  },
  // POST unsubscribe
  contact: '/contact/', // POST contact
  faqs: '/faqs/', // GET faqs
  careers: {
    list: '/careers/',
    detail: (career: string) => `/careers/${career}/`,
  },
  blog: {
    list: '/blog/',
    detail: (blog: string) => `/blog/${blog}/`,
  },
  explore: {
    tile: 'explore/tile/{z}/{x}/{y}/',
    geocoding: (query: string) => `/explore/geocoding/?query=${query}`,
    detail: (geoTypeID: number | string, geoID: number | string) =>
      `/explore/detail/${geoTypeID}/${geoID}/`,
  },
};

// ----------------------------------------------------------------------

export const SOCIALS = {
  linkedin: 'https://es.linkedin.com/company/menhir',
  twitter: 'https://twitter.com/menhirAI',
  // instagram: 'https://www.instagram.com/dratio.io/',
  instagram: 'https://join.com/companies/menhir', // Changed to JOIN
  // medium: 'https://medium.com/@dratio.io',
  medium: 'https://www.crunchbase.com/organization/menhir', // Changed to Crunchbase
  share: {
    twitter: (url: string, text: string) =>
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(text + '\n\n' + url)}`,
    whatsapp: (url: string, text: string) =>
      `https://wa.me/?text=${encodeURIComponent(text + '\n\n' + url)}`,
    linkedin: (url: string) =>
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`,
    telegram: (url: string, text: string) =>
      `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`,
  },
};

export const EXTERNAL_URLS = {
  googlePrivacy: 'https://policies.google.com/privacy',
  googleTerms: 'https://policies.google.com/terms',
  apiDocs: 'https://github.com/dratio-io/dratio-python',
};

export const RECAPTCHA_ACTIONS = {
  contact: 'contact',
  updateAccount: 'update',
  getStarted: 'get_started',
  login: 'login',
  refresh: 'refresh',
  requestForgot: 'forgot_password',
  resetPassword: 'forgot_reset_password',
  requestVerification: 'resend_verification',
  unsubscribe: 'unsubscribe',
  changePassword: 'change_password',
  tokenDeletion: 'delete_token',
  tokenCreation: 'create_token',
  datasetDownload: 'dataset_dowload',
  downloadFile: 'download_file',
  socialLogin: 'social_login',
};
