// react
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
// hooks
import useAuth from '../../../hooks/useAuth';
// routes
import { PATH } from '../../../routes/paths';
// components
import MyAvatar from '../../../components/MyAvatar';
import TextMaxLine from '../../../components/TextMaxLine';

// ----------------------------------------------------------------------

function truncate(str: string, n: number) {
  return str.length > n ? `${str.slice(0, n - 1)}...` : str;
}

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props) {
  const { user } = useAuth();

  return (
    // <Link underline="none" color="inherit" component={RouterLink} to={PATH.app.account.root}>
    <RootStyle
      sx={{
        ...(isCollapse && {
          bgcolor: 'transparent',
        }),
      }}
    >
      <MyAvatar />

      <Box
        sx={{
          ml: 2,
          transition: (theme) =>
            theme.transitions.create('width', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(isCollapse && {
            ml: 0,
            width: 0,
          }),
        }}
      >
        <Link color="inherit" component={RouterLink} to={PATH.app.account.root}>
          <TextMaxLine variant="subtitle2" noWrap line={1} persistent>
            {truncate(user?.displayName || '',  16)}
          </TextMaxLine>
        </Link>
        <Link color="text.secondary" component={RouterLink} to={PATH.app.account.root}>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            Beta Subscription
          </Typography>
        </Link>
      </Box>
    </RootStyle>
    // </Link>
  );
}
