// components
import Iconify from '../../../components/Iconify';
// routes
import { PATH } from '../../../routes/paths';

// ----------------------------------------------------------------------

const navConfig = [
  {
    subheader: '',
    items: [
      // {
      //   title: 'Explore',
      //   path: PATH.app.explore,
      //   icon: <Iconify icon="ant-design:compass-filled" />,
      // },
      {
        title: 'Marketplace',
        path: PATH.app.marketplace.root,
        icon: <Iconify icon="eva:layers-fill" />,
      },
      {
        title: 'API',
        path: PATH.app.api,
        icon: <Iconify icon="ant-design:robot-filled" />,
      },
      {
        title: 'Logout',
        path: PATH.app.logout,
        icon: <Iconify icon="ic:twotone-logout" />,
      },
    ],
  },
];

export default navConfig;
