// react
import { lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import UserBasedGuard from '../guards/UserBasedGuard';
import VerificationGuard from '../guards/VerificationGuard';
// components
import { Loadable } from '../components/Loadable';
// routes
import { PATH } from './paths';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        {
          path: PATH.login,
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: PATH.getStarted,
          element: (
            <GuestGuard>
              <GetStarted />
            </GuestGuard>
          ),
        },
        {
          path: PATH.forgot,
          element: <ForgotPassword />,
        },
        {
          path: PATH.newPassword,
          element: <NewPassword />,
        },
        { path: PATH.unsubscribe(':token'), element: <Unsubscribe /> },
        {
          path: PATH.verify,
          element: (
            <VerificationGuard>
              <VerifyCode />
            </VerificationGuard>
          ),
        },
        {
          path: PATH.requestVerify,
          element: (
            <VerificationGuard>
              <RequestVerifyCode />
            </VerificationGuard>
          ),
        },
        {
          path: PATH.welcome,
          element: (
            <UserBasedGuard test={(user) => user.isVerified && !user.isApproved}>
              <Welcome />
            </UserBasedGuard>
          ),
        },
      ],
    },
    // Main Routes
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: PATH.solutions, element: <Solutions /> },
        { path: PATH.contact, element: <Contact /> },
        { path: PATH.careers.root, element: <Careers /> },
        { path: PATH.careers.career(':id'), element: <CareerDetails /> },
        { path: PATH.faqs, element: <Faqs /> },
        { path: PATH.legal.root, element: <Legal /> },
        { path: PATH.legal.privacy, element: <Privacy /> },
        { path: PATH.legal.terms, element: <Terms /> },
        { path: PATH.legal.cookies, element: <Cookies /> },
        // { path: PATH.blog.root, element: <BlogPosts /> },
        // { path: PATH.blog.post(':id'), element: <BlogPost /> },
        { path: PATH.marketplace.root, element: <Marketplace /> },
        { path: PATH.marketplace.dataset(':id'), element: <DatasetDetails /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: PATH.app.root, element: <Navigate to={PATH.app.marketplace.root} replace /> },
        { path: PATH.app.account.root, element: <UserAccount /> },
        { path: PATH.app.account.invoice, element: <UserBilling /> },
        { path: PATH.app.explore, element: <Explore /> },
        { path: PATH.app.notifications, element: <Notifications /> },
        { path: PATH.app.laboratory, element: <Laboratory /> },
        { path: PATH.app.logout, element: <Logout /> },
        { path: PATH.app.api, element: <API /> },
        // marketplace (inside API)
        { path: PATH.app.marketplace.root, element: <Marketplace /> },
        { path: PATH.app.marketplace.dataset(':id'), element: <DatasetDetails /> },
        { path: PATH.app.urban, element: <Urban /> },
      ],
    },
    {
      path: '*',
      element: (
        <AuthGuard>
          <LogoOnlyLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <Page404 /> }],
    },
  ]);
}

// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const Solutions = Loadable(lazy(() => import('../pages/Solutions')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));

// Careers
const Careers = Loadable(lazy(() => import('../pages/careers/Careers')));
const CareerDetails = Loadable(lazy(() => import('../pages/careers/CareerDetails')));

// Frequent asked questions
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));

// Legal
const Legal = Loadable(lazy(() => import('../pages/legal/Legal')));
const Privacy = Loadable(lazy(() => import('../pages/legal/LegalPrivacy')));
const Terms = Loadable(lazy(() => import('../pages/legal/LegalTerms')));
const Cookies = Loadable(lazy(() => import('../pages/legal/LegalCookies')));

// Blog
// const BlogPosts = Loadable(lazy(() => import('../pages/blog/BlogPosts')));
// const BlogPost = Loadable(lazy(() => import('../pages/blog/BlogPost')));

// Marketplace
const Marketplace = Loadable(lazy(() => import('../pages/marketplace/Marketplace')));
const DatasetDetails = Loadable(lazy(() => import('../pages/marketplace/MarketplaceDetails')));
//const DatasetDetails = Loadable(lazy(() => import('../pages/marketplace/public/DatasetDetails')));

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const GetStarted = Loadable(lazy(() => import('../pages/auth/GetStarted')));
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/ForgotPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const RequestVerifyCode = Loadable(lazy(() => import('../pages/auth/RequestVerifyCode')));
const Welcome = Loadable(lazy(() => import('../pages/auth/Welcome')));
const Logout = Loadable(lazy(() => import('../pages/auth/Logout')));
// Mailing lists
const Unsubscribe = Loadable(lazy(() => import('../pages/Unsubscribe')));

// Errors
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// Account
const UserAccount = Loadable(lazy(() => import('../pages/account/UserAccount')));
const UserBilling = Loadable(lazy(() => import('../pages/account/UserInvoices')));
const Notifications = Loadable(lazy(() => import('../pages/Notifications')));

// App
const Laboratory = Loadable(lazy(() => import('../pages/Laboratory')));
const Explore = Loadable(lazy(() => import('../pages/Explore')));
const API = Loadable(lazy(() => import('../pages/API')));

// App marketplace
// const AppMarketplace = Loadable(lazy(() => import('../pages/marketplace/AppMarketplace')));

const Urban = Loadable(lazy(() => import('../pages/Urban')));
