import jwtDecode from 'jwt-decode';
// routes
import { PATH, PATH_API, RECAPTCHA_ACTIONS } from '../routes/paths';
//
import axios from './axios';
import { IGoogleReCaptchaConsumerProps } from 'react-google-recaptcha-v3';
// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (
  exp: number,
  refreshToken: string,
  executeRecaptcha: IGoogleReCaptchaConsumerProps['executeRecaptcha']
) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  const timeLeft = exp * 1000 - currentTime - 10000;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    if (executeRecaptcha) {
      executeRecaptcha(RECAPTCHA_ACTIONS.refresh).then((recaptcha) => {
        axios
          .post(PATH_API.auth.refresh, {
            refresh: refreshToken,
            recaptcha,
          })
          .then((response) => {
            const accessToken: string = response.data.access;
            setSession(accessToken, refreshToken, executeRecaptcha);
          })
          .catch((error) => {
            console.error(error);
            alert('Session expired. For your security, log in again.');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            window.location.href = PATH.login;
          });
      });
    } else {
      alert('Session expired. For your security, log in again.');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.href = PATH.login;
    }
  }, timeLeft);
};

const setSession = (
  accessToken: string | null,
  refreshToken: string | null,
  executeRecaptcha: IGoogleReCaptchaConsumerProps['executeRecaptcha'] | null
) => {
  if (accessToken && refreshToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode<{ exp: number }>(accessToken); // ~5 days by minimals server
    if (executeRecaptcha) {
      handleTokenExpired(exp, refreshToken, executeRecaptcha);
    }
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
