// routes
import { PATH } from '../../routes/paths';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: PATH.home,
  },
  {
    title: 'Solutions',
    icon: <Iconify icon={'eva:bulb-fill'} {...ICON_SIZE} />,
    path: PATH.solutions,
  },
  // {
  //   title: 'Blog',
  //   icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
  //   path: PATH.blog.root,
  // },

  {
    title: 'Marketplace',
    icon: <Iconify icon={'eva:layers-fill'} {...ICON_SIZE} />,
    path: PATH.marketplace.root,
  },
  {
    title: 'Login',
    icon: <Iconify icon={'eva:people-fill'} {...ICON_SIZE} />,
    path: PATH.login,
  },
];

export default menuConfig;
