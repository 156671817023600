// react
import { ReactNode } from 'react';
// components
import ThemeContrast from './ThemeContrast';
//import ThemeRtlLayout from './ThemeRtlLayout';
import ThemeColorPresets from './ThemeColorPresets';
import ThemeLocalization from './ThemeLocalization';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function ThemeSettings({ children }: Props) {
  return (
    <ThemeColorPresets>
      <ThemeContrast>
        <ThemeLocalization>
          {/* <ThemeRtlLayout>{children}</ThemeRtlLayout> */}
          {children}
        </ThemeLocalization>
      </ThemeContrast>
    </ThemeColorPresets>
  );
}
