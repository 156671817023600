// redux
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// types
import { MarketplaceState } from '../../@types/marketplace';
// store instance
import { dispatch } from '../store';
// routes
import { PATH_API } from '../../routes/paths';
// ----------------------------------------------------------------------

const initialState: MarketplaceState = {
  isLoading: false,
  error: null,
  datasets: [],
  categories: [],
  scopes: [],
  levels: [],
  plans: [],
  sortBy: null,
  filters: {
    queryText: '',
    categories: [],
    scope: [],
    level: [],
    plans: [],
  },
  explorer: {
    baseLayer: 'simple',
    dataLayer: 'auto',
    selectedObject: null,
    detailOpen: false,
  },
};

const slice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DATASETS
    setDatasets(state, action) {
      state.datasets = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByItems(state, action) {
      state.sortBy = action.payload;
    },

    filterByCategories(state, action) {
      state.filters.categories = action.payload;
    },

    filterByText(state, action) {
      state.filters.queryText = action.payload;
    },

    filterByScope(state, action) {
      state.filters.scope = action.payload;
    },

    filterByLevel(state, action) {
      state.filters.level = action.payload;
    },

    filterByPlan(state, action) {
      state.filters.plans = action.payload;
    },

    setExplorerDetailOpen(state, action) {
      state.explorer.detailOpen = action.payload;
    },

    clearFilters(state, action) {
      state.filters.categories = [];
      state.filters.scope = [];
      state.filters.level = [];
      state.filters.plans = [];
      state.filters.queryText = '';
    },

    // Fetch filters
    setCategories(state, action) {
      state.categories = action.payload;
    },
    setScopes(state, action) {
      state.scopes = action.payload;
    },
    setLevels(state, action) {
      state.levels = action.payload;
    },
    setPlans(state, action) {
      state.plans = action.payload;
    },

    setDataLayer(state, action) {
      if (state.explorer.dataLayer === action.payload) {
        state.explorer.dataLayer = 'none';
      } else {
        state.explorer.dataLayer = action.payload;
      }
    },
    setBaseLayer(state, action) {
      state.explorer.baseLayer = action.payload;
    },
    setSelectedObject(state, action) {
      if (
        state.explorer.selectedObject &&
        state.explorer.selectedObject.id === action.payload?.id
      ) {
        state.explorer.selectedObject = null;
      } else {
        state.explorer.selectedObject = action.payload;
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByItems,
  filterByCategories,
  filterByScope,
  filterByLevel,
  filterByPlan,
  filterByText,
  clearFilters,
  setBaseLayer,
  setDataLayer,
  setExplorerDetailOpen,
  setSelectedObject,
} = slice.actions;

// ----------------------------------------------------------------------

export function getItems() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const responseDatasets: { data: any } = await axios.get(PATH_API.marketplace.datasets);
      dispatch(slice.actions.setDatasets(responseDatasets.data));

      const responseCategories: { data: any } = await axios.get(PATH_API.marketplace.categories);
      dispatch(slice.actions.setCategories(responseCategories.data));

      const responseScopes: { data: any } = await axios.get(PATH_API.marketplace.scopes);
      dispatch(slice.actions.setScopes(responseScopes.data));

      const responseLevels: { data: any } = await axios.get(PATH_API.marketplace.levels);
      dispatch(slice.actions.setLevels(responseLevels.data));
      // Hardcoded plans
      const plans = [{ code: 'basic', name: 'Basic Plan' }];
      dispatch(slice.actions.setPlans(plans));

      dispatch(slice.actions.endLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
