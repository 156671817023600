// redux
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import marketplaceReducer from './slices/marketplace';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const marketplacePersistConfig = {
  key: 'marketplace',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  marketplace: persistReducer(marketplacePersistConfig, marketplaceReducer),
});

export { rootPersistConfig, rootReducer };
