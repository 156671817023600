// react
import { ReactNode, forwardRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// mui
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
//hooks
import useCookiesPreferences from '../hooks/useCookiesPreferences';
// path
import { PATH } from '../routes/paths';
// config
import { GA_TRACKING_CODE } from '../config';
// google analytics
import ReactGA from 'react-ga4';
// components
import Scrollbar from './Scrollbar';

//------------------------------------------------------------------------------------

function getInitializeCookies(
  neccesary: boolean,
  analytics: boolean,
  setStorage: boolean,
  handleAnalyticContext?: (a: boolean) => void
) {
  if (setStorage) {
    const timeDelay = analytics ? 365 : 15;
    const expiration = new Date();
    // computes expiration date
    expiration.setDate(expiration.getDate() + timeDelay);

    const storage = JSON.stringify({ consentDate: new Date(), expiration, neccesary, analytics });
    localStorage.setItem('cookiePreferences', storage);
  }
  // set analytic context
  if (handleAnalyticContext) {
    handleAnalyticContext(analytics);
  }
  // if const to analytics. Initialize ga
  if (analytics && GA_TRACKING_CODE) {
    ReactGA.initialize(GA_TRACKING_CODE);
  }
}

//------------------------------------------------------------------------------------

type CookieConsentType = {
  consentDate: Date;
  expiration: Date;
  neccesary: boolean;
  analytics: boolean;
};

function getDefaultConsent(): boolean {
  const cookiePreferences = localStorage.getItem('cookiePreferences');

  if (!cookiePreferences) {
    return true;
  }
  try {
    const consent: CookieConsentType = JSON.parse(cookiePreferences);
    const now = new Date();

    // remove consent if expired
    if (consent.expiration < now) {
      localStorage.removeItem('cookiePreferences');
      return true;
    }
    // Initializes cookies without setting again the consent
    getInitializeCookies(consent.neccesary, consent.analytics, false);
  } catch {
    return true;
  }

  return false;
}

function initializeContext(handleAnalyticContext: (a: boolean) => void): void {
  const cookiePreferences = localStorage.getItem('cookiePreferences');
  if (cookiePreferences) {
    try {
      const consent: CookieConsentType = JSON.parse(cookiePreferences);
      if (consent.analytics) {
        handleAnalyticContext(true);
      }
    } catch {}
  }
}

//------------------------------------------------------------------------------------

type CookieSettingProps = {
  analytic: boolean;
  setAnalyticConsent: () => void;
};

function CookieSettings({ analytic, setAnalyticConsent }: CookieSettingProps) {
  return (
    <Stack sx={{ mt: 2 }}>
      <Scrollbar sx={{ maxBlockSize: '50vh' }}>
        <AlertTitle>Cookie settings</AlertTitle>
        <Typography variant="subtitle2">
          Some cookies are needed for technical purposes, they are therefore exempted from consent.
          You can disable all other cookies that are not necessary for the correct working of the
          site.
        </Typography>
        <FormControlLabel
          control={<Checkbox color="secondary" checked={true} />}
          label={<Typography variant="subtitle2">Strictly neccesary cookies</Typography>}
          color="inherit"
        />
        <Typography variant="subtitle2">
          Cookies necessary to enable basic functions of this site, such as provide a secure
          mecanism for session login or adjusting your consent preferences.
          <br />
          <br />
          In addition,&nbsp;
          <Link href="https://policies.google.com/?hl=en-GB" color="inherit" underline="always">
            Google reCAPTCHA
          </Link>
          &nbsp; service is used as an anti-spam protection to ensure that the platform is available
          to real users of the platform. This service may create third-party cookies for its correct
          functioning. If these cookies are disabled, parts of the platform may not function
          correctly.
        </Typography>
        <FormControlLabel
          control={<Checkbox checked={analytic} color="secondary" onClick={setAnalyticConsent} />}
          label={<Typography variant="subtitle2">Analytic cookies (optional)</Typography>}
          color="inherit"
        />
        <Typography variant="subtitle2" sx={{ mb: 3 }}>
          Cookies that measure how our site is used. We use this information to improve our website
          and services, such as those created by&nbsp; Google Analytics 4 . With these cookies we
          measure in an anonymized manner, for example, how often users return and which functions
          they use.
        </Typography>
        <AlertTitle>Legal Terms</AlertTitle>
        <Typography variant="subtitle2" sx={{ mb: 3 }}>
          For detailed information, please visit our&nbsp;
          <Link component={RouterLink} to={PATH.legal.root} color="inherit" underline="always">
            Legal Section
          </Link>
          .
        </Typography>
      </Scrollbar>
    </Stack>
  );
}

//------------------------------------------------------------------------------------

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//------------------------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function CookieConsentProvider({ children }: Props) {
  const { onSetAnalytics } = useCookiesPreferences();
  const [showConsent, setShowConsent] = useState<boolean>(() => getDefaultConsent());
  const [showSettings, setShowSettings] = useState<boolean>(false);

  const [analyticCookies, setAnalyticCookies] = useState<boolean>(false);

  const handleAcceptCookies = (neccesary: boolean, analytics: boolean) => {
    getInitializeCookies(neccesary, analytics, true, onSetAnalytics);
    setShowConsent(false);
  };

  const handleChangeAnalytics = () => {
    setAnalyticCookies((consent) => !consent);
  };

  useEffect(
    () => initializeContext(onSetAnalytics),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Snackbar open={showConsent} sx={{ maxWidth: '40em' }}>
        <Alert severity="info" sx={{ width: '100%', pt: 2 }}>
          <AlertTitle>We value your privacy</AlertTitle>
          <Typography variant="subtitle2">
            This site uses own and third-party cookies for its proper functioning and improve your
            experience. In no case do we use cookies for advertising purposes. You can get further
            details in our&nbsp;
            <Link component={RouterLink} to={PATH.legal.cookies} color="inherit" underline="always">
              Cookie Policy
            </Link>
            . In addition, by clicking Accept you confirm that you accept our&nbsp;
            <Link component={RouterLink} to={PATH.legal.terms} color="inherit" underline="always">
              Terms and Conditions
            </Link>
            &nbsp;and&nbsp;
            <Link component={RouterLink} to={PATH.legal.privacy} color="inherit" underline="always">
              Privacy Policy
            </Link>
            .
          </Typography>
          {showSettings && (
            <CookieSettings analytic={analyticCookies} setAnalyticConsent={handleChangeAnalytics} />
          )}

          <Stack direction="row-reverse" spacing={2} sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              sx={{ textTransform: 'none' }}
              onClick={() => handleAcceptCookies(true, true)}
            >
              Accept {showSettings && 'all'}
            </Button>
            {showSettings ? (
              <Button
                variant="outlined"
                color="inherit"
                size="medium"
                sx={{ textTransform: 'none' }}
                onClick={() => handleAcceptCookies(true, analyticCookies)}
              >
                Accept selected cookies
              </Button>
            ) : (
              <Button
                variant="text"
                color="inherit"
                size="small"
                onClick={() => setShowSettings(true)}
                sx={{ textTransform: 'none' }}
              >
                Configure cookies
              </Button>
            )}
          </Stack>
        </Alert>
      </Snackbar>
      {children}
    </>
  );
}
