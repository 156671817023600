// react
import { useMemo } from 'react';
// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
// components
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();

  const avatar = useMemo(() => createAvatar(user?.displayName || 'user'), [user]);

  return (
    <Avatar alt={user?.displayName} color={avatar.color} {...other}>
      {avatar.name}
    </Avatar>
  );
}
