// react
import { useContext } from 'react';
// context
import { CookiesPreferencesContext } from '../contexts/CookiesPreferencesContext';

// ----------------------------------------------------------------------

const useCookiesPreferences = () => useContext(CookiesPreferencesContext);

export default useCookiesPreferences;
