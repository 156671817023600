// react
import { Link as RouterLink } from 'react-router-dom';
// @mui
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
// components
import TextMaxLine from '../../../components/TextMaxLine';
// routes
import { PATH } from '../../../routes/paths';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 5, mt: 5, width: 1, textAlign: 'center', display: 'block' }}
    >
      <div>
        <TextMaxLine
          line={1}
          persistent
          variant="body2"
          sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}
        >
          Need help?
        </TextMaxLine>
        <TextMaxLine
          persistent
          line={1}
          variant="body2"
          sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}
        >
          Check the&nbsp;
          <Link component={RouterLink} to={PATH.faqs} color="inherit" variant="button">
            FAQs
          </Link>
        </TextMaxLine>
      </div>
    </Stack>
  );
}
