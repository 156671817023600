// i18n
import './locales/i18n';

// highlight -> move to utils/formatcode
//import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// map -> Moved to sections/contact/ContactMap.tsx
// import './utils/mapboxgl';
// import 'mapbox-gl/dist/mapbox-gl.css';

// editor -> Removed, not used right now
// import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// @mui
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// redux
import { store, persistor } from './redux/store';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { CookiesPreferencesProvider } from './contexts/CookiesPreferencesContext';
import { AuthProvider } from './contexts/JWTContext';

//
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
// google Recaptcha
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// config
import { RECAPTCHA_API, AUTH0_API, PATH_AFTER_SOCIAL_LOGIN } from './config';

import { Auth0Provider } from '@auth0/auth0-react';

// ----------------------------------------------------------------------

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_API}>
    <AuthProvider>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider>
                <CollapseDrawerProvider>
                  <Auth0Provider
                    domain={AUTH0_API.domain}
                    clientId={AUTH0_API.clientId}
                    authorizationParams={{
                      redirect_uri: PATH_AFTER_SOCIAL_LOGIN,
                    }}
                  >
                    <CookiesPreferencesProvider>
                      <BrowserRouter>
                        <App />
                      </BrowserRouter>
                    </CookiesPreferencesProvider>
                  </Auth0Provider>
                </CollapseDrawerProvider>
              </SettingsProvider>
            </LocalizationProvider>
          </PersistGate>
        </ReduxProvider>
      </HelmetProvider>
    </AuthProvider>
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
