// react
import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box sx={{ width: 180, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="801.7 135.2 436.6 137.5">
        <title>dratio.io</title>
        <g id="Layer 1">
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path
                  id="&lt;Compound Path&gt;"
                  fillRule="evenodd"
                  fill="#005249"
                  d="m975.7 242.5h-12.1v-4.8q-7.5 5.6-16.7 5.6-12 0-20.4-8.7-8.4-8.5-8.4-20.6 0-12.1 8.4-20.7 8.4-8.6 20.4-8.6 9.2 0 16.7 5.5v-23.7h12.1zm-28.8-11.4q6.8 0 11.8-5 4.9-5.1 4.9-12.2 0-7.1-4.9-12.1-5-5.1-11.8-5.1-7 0-11.9 5.1-4.9 5-4.8 12.1-0.1 7.1 4.8 12.2 4.9 5 11.9 5z"
                />
                <path
                  id="&lt;Compound Path&gt;"
                  fill="#005249"
                  d="m998.4 189.9q6.6-4.8 14.6-4.8h6v11.8h-6q-6 0-10.3 5-4.3 4.9-4.3 11.8v28.8h-11.8v-57.4h11.8z"
                />
                <path
                  id="&lt;Compound Path&gt;"
                  fillRule="evenodd"
                  fill="#005249"
                  d="m1080.4 242.5h-12.1v-5q-7.5 5.6-16.7 5.6-11.9 0-20.3-8.6-8.5-8.6-8.5-20.7 0-12.1 8.5-20.7 8.4-8.6 20.3-8.6 9.2 0 16.7 5.5v-4.9h12.1zm-28.8-11.5q6.9 0 11.8-5 4.9-5 4.9-12.2 0-7.1-4.9-12.2-4.9-5-11.8-5-6.9 0-11.9 5-4.8 5.1-4.8 12.2 0 7.2 4.8 12.2 5 5 11.9 5z"
                />
                <path
                  id="&lt;Compound Path&gt;"
                  fill="#005249"
                  d="m1107.2 185.1h7.8v11.8h-7.8v45.6h-11.8v-45.6h-7.7v-11.8h7.7v-18.6h11.8z"
                />
                <path
                  id="&lt;Compound Path&gt;"
                  fillRule="evenodd"
                  fill="#005249"
                  d="m1133.9 167.8q1.9 1.9 1.9 4.7 0 2.7-1.9 4.6-1.8 1.9-4.6 1.9-2.7 0-4.6-1.9-1.9-1.9-1.9-4.6 0-2.8 1.9-4.7 1.9-1.9 4.6-1.9 2.8 0 4.6 1.9zm-10.5 74.7h11.8v-57.4h-11.8z"
                />
                <path
                  id="&lt;Compound Path&gt;"
                  fillRule="evenodd"
                  fill="#005249"
                  d="m1174.2 184.5q11.9 0 20.4 8.6 8.4 8.6 8.4 20.7 0 12.1-8.4 20.7-8.5 8.6-20.4 8.6-11.9 0-20.3-8.6-8.5-8.6-8.5-20.7 0-12.1 8.5-20.7 8.4-8.6 20.3-8.6zm0 46.5q6.9 0 11.8-5 4.9-5.1 4.9-12.2 0-7.1-4.9-12.2-4.9-5-11.8-5-6.9 0-11.8 5-4.9 5.1-4.9 12.2 0 7.1 4.9 12.2 4.9 5 11.8 5z"
                />
              </g>
              <path
                id="&lt;Compound Path&gt;"
                fillRule="evenodd"
                fill="#005249"
                d="m1217.7 220.3q0.6 0.5 0.6 1.4 0 0.8-0.6 1.3-0.5 0.6-1.4 0.6-0.8 0-1.3-0.6-0.6-0.5-0.6-1.3 0-0.9 0.6-1.4 0.5-0.6 1.3-0.6 0.9 0 1.4 0.6zm-3.1 22.2h3.5v-17.1h-3.5z"
              />
              <path
                id="&lt;Compound Path&gt;"
                fillRule="evenodd"
                fill="#005249"
                d="m1229.7 225.2q3.5 0 6.1 2.6 2.5 2.5 2.5 6.1 0 3.7-2.5 6.2-2.6 2.6-6.1 2.6-3.5 0-6-2.6-2.6-2.5-2.6-6.2 0-3.6 2.6-6.1 2.5-2.6 6-2.6zm0 13.9q2.1 0 3.5-1.5 1.5-1.5 1.5-3.7 0-2.1-1.5-3.6-1.4-1.5-3.5-1.5-2 0-3.5 1.5-1.5 1.5-1.5 3.6 0 2.2 1.5 3.7 1.5 1.5 3.5 1.5z"
              />
              <path
                id="&lt;Compound Path&gt;"
                fill="#005249"
                d="m1209.6 239.3q0.5 0.6 0.5 1.4 0 0.8-0.5 1.4-0.6 0.6-1.4 0.6-0.8 0-1.4-0.6-0.5-0.6-0.5-1.4 0-0.8 0.5-1.4 0.6-0.5 1.4-0.5 0.8 0 1.4 0.5z"
              />
            </g>
            <g id="&lt;Group&gt;">
              <path
                id="&lt;Path&gt;"
                fill="#007B55"
                d="m872.2 250.1l-22.6 22.6-33.9-33.8c-9.3-9.4-14-21.6-14-33.9v-41.5h31.9v7.9 33.6q0 0.8 0.1 1.6 0.1 0.3 0.1 0.7 0.1 0.4 0.2 0.8 0 0.4 0.1 0.8 0.1 0.4 0.2 0.7 0.2 0.4 0.3 0.8 0.1 0.4 0.3 0.7 0.1 0.4 0.3 0.7 0.1 0.4 0.3 0.7 0.2 0.4 0.4 0.7 0.2 0.3 0.4 0.7 0.3 0.3 0.5 0.7 0.2 0.2 0.5 0.5 0.4 0.6 1 1.2 0 0 0 0l31.9 31.9z"
              />
              <path
                id="&lt;Path&gt;"
                fill="#00ab55"
                d="m865.6 149.3v22.1 33.6q0-0.8-0.1-1.6-0.1-0.3-0.1-0.7-0.1-0.4-0.1-0.8-0.1-0.4-0.2-0.8-0.1-0.4-0.2-0.7-0.1-0.4-0.3-0.8-0.1-0.3-0.3-0.7-0.1-0.4-0.3-0.7-0.1-0.4-0.3-0.7-0.2-0.3-0.4-0.7-0.2-0.3-0.4-0.7-0.3-0.3-0.5-0.7-0.2-0.2-0.5-0.5-1-1.2-2.2-2.2-0.3-0.3-0.5-0.5-0.4-0.2-0.7-0.5-0.4-0.2-0.7-0.4-0.3-0.2-0.7-0.4-0.3-0.2-0.7-0.3-0.3-0.2-0.7-0.3-0.3-0.2-0.7-0.3-0.4-0.1-0.8-0.3-0.3-0.1-0.7-0.1-0.4-0.2-0.8-0.2-0.4-0.1-0.8-0.2-0.4 0-0.7-0.1-0.8-0.1-1.6-0.1 0 0 0 0-0.8 0-1.6 0.1-0.3 0.1-0.7 0.1-0.4 0.1-0.8 0.2-0.4 0-0.8 0.2-0.4 0-0.7 0.1-0.4 0.2-0.8 0.3-0.4 0.1-0.7 0.3-0.4 0.1-0.7 0.3-0.4 0.1-0.7 0.3-0.3 0.2-0.7 0.4-0.3 0.2-0.7 0.4-0.3 0.3-0.6 0.5-0.3 0.2-0.6 0.5-0.6 0.5-1.2 1 0 0 0 0-0.5 0.6-1 1.2-0.3 0.3-0.5 0.6-0.2 0.3-0.5 0.6-0.2 0.4-0.4 0.7-0.2 0.4-0.4 0.7-0.2 0.3-0.3 0.7-0.2 0.4-0.3 0.7-0.2 0.4-0.3 0.7-0.1 0.4-0.3 0.8-0.1 0.3-0.2 0.7-0.1 0.4-0.1 0.8-0.1 0.4-0.2 0.8 0 0.4-0.1 0.7-0.1 0.8-0.1 1.6v-33.6-22.1z"
              />
              <path
                id="&lt;Path&gt;"
                fill="#5BE584"
                d="m897.5 135.2v69.8c0 12.3-4.7 24.5-14 33.9l-11.3 11.2-2-1.9-20.6-20.6 11.3-11.3q0 0 0 0 0.5-0.6 1-1.2 0.3-0.3 0.5-0.5 0.2-0.4 0.5-0.7 0.2-0.4 0.4-0.7 0.2-0.3 0.4-0.7 0.2-0.3 0.3-0.7 0.2-0.3 0.3-0.7 0.2-0.3 0.3-0.7 0.2-0.4 0.3-0.8 0.1-0.3 0.2-0.7 0.1-0.4 0.2-0.8 0-0.4 0.1-0.8 0-0.4 0.1-0.7 0.1-0.8 0.1-1.6v-33.6-36.2z"
              />
            </g>
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}

export default memo(Logo);
