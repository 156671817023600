// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
//import ChartStyle from './components/chart/ChartStyle';
import ScrollToTop from './components/ScrollToTop';
//import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import CookieConsentProvider from './components/CookieConsentProvider';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <CookieConsentProvider>
              {/* <ProgressBarStyle /> */}
              {/* <ChartStyle /> */}
              <ScrollToTop />
              <Router />
            </CookieConsentProvider>
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
