// react
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// layout
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { isCollapse } = useCollapseDrawer();

  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />
      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <Outlet />
    </Box>
  );
}
